import * as Yup from 'yup'
import { ErrorMessage } from './messages'
import { EMAIL_NUMBER, Phone_NUMBER_REGEX } from './constantregex'

export let FreeTrialFormValidationSchema =Yup.object().shape({
    firstName:Yup.string().required(ErrorMessage.firstNameRequired),
    lastName:Yup.string().required(ErrorMessage.lastNameRequired),
    email:Yup.string().required(ErrorMessage.emailRequired).matches(EMAIL_NUMBER, ErrorMessage.emailValid, ErrorMessage.emailValid),
    phoneNumber:Yup.string().matches(Phone_NUMBER_REGEX, ErrorMessage.phoneNumberValid),

})

export let SubscribeEmailValidation = Yup.object({
    email:Yup.string().required(ErrorMessage.emailRequired).matches(EMAIL_NUMBER, ErrorMessage.emailValid, ErrorMessage.emailValid)
})

