import React from 'react'
import chart1 from '../../assets/image/chart-1.png'

function ReliableData() {
    return (
        <div className="overflow-hidden reliable-data-section">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-5 col-md-12 col-sm-12">
                        <div className="common-title" data-aos="fade-right">
                            <h5>Superfast and Reliable</h5>
                            <p>Looking to get things done fast? SmartApps brings the best-in-className generative AI
                                tools to get insights and take actions consistently within minutes instead of days and
                                weeks.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div>
                            <img src={chart1} alt='chart'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReliableData
