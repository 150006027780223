import React from 'react'
import User1 from '../../assets/image/user/user-1.png'
import User2 from '../../assets/image/user/user-2.png'
import User3 from '../../assets/image/user/user-3.png'
import {Link} from "react-router-dom";


function Leadership() {
    return (
        <section className="leadership-section overflow-hidden">
            <div className="container">
                <div className="text-center">
                    <h3>Leadership</h3>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-4 col-sm-12">
                        <div className="leadership-user-body">
                            <div className='profile-images'>
                                <img src={User1}/>
                                <Link className="social-link" to="https://www.linkedin.com/in/alekhjindal/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"
                                         fill="none">
                                        <path d="M12.833 11.0634V11.0322C12.833 11.0322 12.8018 11.0322 12.833 11.0634Z"
                                              fill="white"/>
                                        <path
                                            d="M22.0813 0.243164H1.76147C0.78637 0.243164 0 0.998079 0 1.97318V22.5132C0 23.4568 0.78637 24.2432 1.76147 24.2432H22.0813C23.0564 24.2432 23.8427 23.4883 23.8427 22.5132V1.94172C23.8427 0.998079 23.0564 0.243164 22.0813 0.243164ZM7.2346 20.3113H3.64876V9.49087H7.2346V20.3113ZM5.44168 8.0125C4.21494 8.0125 3.42857 7.19467 3.42857 6.15666C3.42857 5.0872 4.2464 4.30083 5.47313 4.30083C6.69987 4.30083 7.45478 5.11866 7.48624 6.15666C7.48624 7.16322 6.69987 8.0125 5.44168 8.0125ZM20.2254 20.3113H16.6081V14.5236C16.6081 13.0767 16.0734 12.0702 14.7837 12.0702C13.7772 12.0702 13.211 12.7307 12.9279 13.3913C12.8336 13.6114 12.8021 13.9575 12.8021 14.272V20.3113H9.21625C9.21625 20.3113 9.27916 10.4974 9.21625 9.49087H12.8021V11.0322C13.2739 10.3087 14.1232 9.23923 16.0419 9.23923C18.401 9.23923 20.194 10.7805 20.194 14.1147V20.3113H20.2254Z"
                                            fill="white"/>
                                    </svg>
                                </Link>
                            </div>
                            <h4>Alekh Jindal</h4>
                            <span>CEO</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="leadership-user-body">
                            <div className='profile-images'>
                                <img src={User2}/>
                                <Link className="social-link" to="https://www.linkedin.com/in/shi-qiao-590a26b1/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"
                                         fill="none">
                                        <path d="M12.833 11.0634V11.0322C12.833 11.0322 12.8018 11.0322 12.833 11.0634Z"
                                              fill="white"/>
                                        <path
                                            d="M22.0813 0.243164H1.76147C0.78637 0.243164 0 0.998079 0 1.97318V22.5132C0 23.4568 0.78637 24.2432 1.76147 24.2432H22.0813C23.0564 24.2432 23.8427 23.4883 23.8427 22.5132V1.94172C23.8427 0.998079 23.0564 0.243164 22.0813 0.243164ZM7.2346 20.3113H3.64876V9.49087H7.2346V20.3113ZM5.44168 8.0125C4.21494 8.0125 3.42857 7.19467 3.42857 6.15666C3.42857 5.0872 4.2464 4.30083 5.47313 4.30083C6.69987 4.30083 7.45478 5.11866 7.48624 6.15666C7.48624 7.16322 6.69987 8.0125 5.44168 8.0125ZM20.2254 20.3113H16.6081V14.5236C16.6081 13.0767 16.0734 12.0702 14.7837 12.0702C13.7772 12.0702 13.211 12.7307 12.9279 13.3913C12.8336 13.6114 12.8021 13.9575 12.8021 14.272V20.3113H9.21625C9.21625 20.3113 9.27916 10.4974 9.21625 9.49087H12.8021V11.0322C13.2739 10.3087 14.1232 9.23923 16.0419 9.23923C18.401 9.23923 20.194 10.7805 20.194 14.1147V20.3113H20.2254Z"
                                            fill="white"/>
                                    </svg>
                                </Link>
                            </div>
                            <h4>Shi Qiao</h4>
                            <span>CTO</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="leadership-user-body">
                            <div className='profile-images'>
                                <img src={User3}/>
                                <Link className="social-link" to="https://www.linkedin.com/in/rony-chatterjee-phd-b2488912/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"
                                         fill="none">
                                        <path d="M12.833 11.0634V11.0322C12.833 11.0322 12.8018 11.0322 12.833 11.0634Z"
                                              fill="white"/>
                                        <path
                                            d="M22.0813 0.243164H1.76147C0.78637 0.243164 0 0.998079 0 1.97318V22.5132C0 23.4568 0.78637 24.2432 1.76147 24.2432H22.0813C23.0564 24.2432 23.8427 23.4883 23.8427 22.5132V1.94172C23.8427 0.998079 23.0564 0.243164 22.0813 0.243164ZM7.2346 20.3113H3.64876V9.49087H7.2346V20.3113ZM5.44168 8.0125C4.21494 8.0125 3.42857 7.19467 3.42857 6.15666C3.42857 5.0872 4.2464 4.30083 5.47313 4.30083C6.69987 4.30083 7.45478 5.11866 7.48624 6.15666C7.48624 7.16322 6.69987 8.0125 5.44168 8.0125ZM20.2254 20.3113H16.6081V14.5236C16.6081 13.0767 16.0734 12.0702 14.7837 12.0702C13.7772 12.0702 13.211 12.7307 12.9279 13.3913C12.8336 13.6114 12.8021 13.9575 12.8021 14.272V20.3113H9.21625C9.21625 20.3113 9.27916 10.4974 9.21625 9.49087H12.8021V11.0322C13.2739 10.3087 14.1232 9.23923 16.0419 9.23923C18.401 9.23923 20.194 10.7805 20.194 14.1147V20.3113H20.2254Z"
                                            fill="white"/>
                                    </svg>
                                </Link>
                            </div>
                            <h4>Rony Chatterjee</h4>
                            <span>CPO</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Leadership
