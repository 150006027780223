import {ErrorMessage, Field, Form, Formik} from 'formik'
import React, {useState} from 'react'
import {FreeTrialFormValidationSchema} from '../../constants/validation'
import {PopupModal} from "react-calendly";

function FreeTrialForm() {
    const [isOpen, setisOpen] = useState(false);
    const [prefill, setprefill] = useState({});
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        GoogleSheets: false,
        PowerBI: false,
        Excel: false,
        Mode: false,
        Looker: false,
        SQL: false,
        Tableau: false,
        Others: false,
        Snowflake: false,
        Clickhouse: false,
        BigQuery: false,
        Firebolt: false,
        Postgres: false,
        Dremio: false,
        RedShift: false,
        Azure: false
    }

    const handleSubmit = (values) => {
        //window.location = `mailto:contact@smart-apps.ai?subject=I want to use your service&body=${JSON.stringify(values)}`;
        // openPopupWidget({url, prefill, pageSettings, utm});
        values = {
            ...values,
            name: `${values.firstName} ${values.lastName}`,
            customAnswers: {a1: JSON.stringify(values, null, 4)}
        };
        setprefill(values);

        setisOpen(true);


    }


    return (

        <div>

            <section className="form-section overflow-hidden">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-4 col-sm-12 mb-4">
                            <div className="left-contant">
                                <div className="common-title">
                                    <h5>Run Generative AI on your data</h5>
                                    <p>Try SmartApps with your data and get reports and dashboards you can use right
                                        now.</p>
                                </div>
                                <div className="mt-5">
                                    <div className="form-secure-data">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM18 5.34L20.16 7.5L10.5 17.16L5.34 12L7.5 9.84L10.5 12.84L18 5.34Z"
                                                fill="#0B95EB"/>
                                        </svg>
                                        <div>
                                            <h3>Hallucination-free</h3>
                                            <p>Stop worrying about out-of-context results.</p>
                                        </div>
                                    </div>
                                    <div className="form-secure-data">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM18 5.34L20.16 7.5L10.5 17.16L5.34 12L7.5 9.84L10.5 12.84L18 5.34Z"
                                                fill="#0B95EB"/>
                                        </svg>
                                        <div>
                                            <h3>Cloud-scale</h3>
                                            <p>Run on data warehouse of any size.</p>
                                        </div>
                                    </div>
                                    <div className="form-secure-data">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM18 5.34L20.16 7.5L10.5 17.16L5.34 12L7.5 9.84L10.5 12.84L18 5.34Z"
                                                fill="#0B95EB"/>
                                        </svg>
                                        <div>
                                            <h3>Enterprise-secure</h3>
                                            <p>Your data remains private and secure.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-container">
                                <h6 className="mb-4">Request a free trial</h6>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={FreeTrialFormValidationSchema}
                                    onSubmit={handleSubmit}>
                                    <Form id="itemForm">
                                        <div className="row">

                                            <div className="col-md-6 col-sm-12">
                                                <div className="register-input">
                                                    <label htmlFor='firstName'>First Name</label>
                                                    <Field type="text" name="firstName" id="firstName"
                                                           placeholder="Your first name"/>
                                                    <span className='text-danger'><ErrorMessage
                                                        name="firstName"/></span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="register-input">
                                                    <label htmlFor="lastName">Last Name</label>
                                                    <Field type="text" name="lastName" id="lastName"
                                                           placeholder="Your last name"/>
                                                    <span className='text-danger'>  <ErrorMessage
                                                        name="lastName"/> </span>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="register-input">
                                                    <label htmlFor='email'>Work email</label>
                                                    <Field type="email" placeholder="Email address" name="email"
                                                           id="email"/>
                                                    <span className='text-danger'>    <ErrorMessage
                                                        name="email"/> </span>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="register-input">
                                                    <label htmlFor='phoneNumber'>Phone</label>
                                                    <Field type="number" placeholder="Your phone" name="phoneNumber"
                                                           id="phoneNumber"/>
                                                    <span className='text-danger'>    <ErrorMessage name="phoneNumber"/></span>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="register-input">
                                                    <label htmlFor='companyName'>Company Name</label>
                                                    <Field type="text" placeholder="Company name" name="companyName"
                                                           id="companyName"/>
                                                    <span className='text-danger'>   <ErrorMessage name="companyName"/> </span>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <span>What data tools do you use at work, if any?</span>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           name='GoogleSheets' id="GoogleSheets"/>
                                                    <label className="form-check-label" htmlFor="GoogleSheets">
                                                        Google Sheets
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="PowerBI" name="PowerBI"/>
                                                    <label className="form-check-label" htmlFor="PowerBI">
                                                        Power BI
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="Excel" name="Excel"/>
                                                    <label className="form-check-label" htmlFor="Excel">
                                                        Excel
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="Mode" name="Mode"/>
                                                    <label className="form-check-label" htmlFor="Mode">
                                                        Mode
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="Looker" name="Looker"/>
                                                    <label className="form-check-label" htmlFor="Looker">
                                                        Looker
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="SQL" name="SQL"/>
                                                    <label className="form-check-label" htmlFor="SQL">
                                                        SQL
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="Tableau" name="Tableau"/>
                                                    <label className="form-check-label" htmlFor="Tableau">
                                                        Tableau
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="Others" name="Others"/>
                                                    <label className="form-check-label" htmlFor="Others">
                                                        Others
                                                    </label>
                                                </div>
                                            </div>
                                            <ErrorMessage name='myCustomCheckboxTest'/>
                                        </div>
                                        <div className="row">
                                            <span>What database do you use?</span>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="Snowflake" name="Snowflake"/>
                                                    <label className="form-check-label" htmlFor="Snowflake">
                                                        Snowflake
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="Clickhouse" name="Clickhouse"/>
                                                    <label className="form-check-label" htmlFor="Clickhouse">
                                                        Clickhouse
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="BigQuery" name="BigQuery"/>
                                                    <label className="form-check-label" htmlFor="BigQuery">
                                                        BigQuery
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="Firebolt" name="Firebolt"/>
                                                    <label className="form-check-label" htmlFor="Firebolt">
                                                        Firebolt
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="Postgres" name="Postgres"/>
                                                    <label className="form-check-label" htmlFor="Postgres">
                                                        Postgres
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="Dremio" name="Dremio"/>
                                                    <label className="form-check-label" htmlFor="Dremio">
                                                        Dremio
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="RedShift" name="RedShift"/>
                                                    <label className="form-check-label" htmlFor="RedShift">
                                                        RedShift
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="Azure" name='Azure'/>
                                                    <label className="form-check-label" htmlFor="Azure">
                                                        Azure
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            <button
                                                type='submit' className="btn theme-blue-btn w-100">Continue to Free
                                                Trial
                                            </button>
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <PopupModal
                url="https://calendly.com/alekh/30min"
                prefill={prefill}
                onModalClose={() => setisOpen(false)}
                open={isOpen}
                /*
                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                 */
                rootElement={document.getElementById("root")}
            />

            {/*<div className="modal fade bd-example-modal-lg" id="staticBackdrop" data-bs-backdrop="static"*/}
            {/*     data-bs-keyboard="false"*/}
            {/*     tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">*/}
            {/*    <div className="modal-dialog modal-lg modal-dialog-centered">*/}
            {/*        <div className="modal-content ">*/}

            {/*            <div className="modal-body">*/}
            {/*                <button type="button" className="btn-close close-videos-model" data-bs-dismiss="modal"*/}
            {/*                        aria-label="Close"></button>*/}
            {/*                */}
            {/*                <InlineWidget url="https://calendly.com/alekh/30min"/>*/}
            {/*            </div>*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default FreeTrialForm
