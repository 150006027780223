import React from 'react'
import {Link} from "react-router-dom"

function UseCases() {
    return (
        <div className="overflow-hidden use-cases-section">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-6 col-sm-12">
                        <div className="cases-title">
                            <h3>Use cases</h3>
                            <a className="btn theme-blue-btn leptop-btn" href="./product">Learn more</a>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="common-title" data-aos="fade-up" data-aos-duration="500">
                            <h5>Talk to your data</h5>
                            <p>Empower your organization with plug-and-play generative AI experience over your database,
                                at any scale.</p>
                        </div>
                        <div className="common-title" data-aos="fade-up" data-aos-duration="1000">
                            <h5>Create data stories</h5>
                            <p>Generate real-time dashboards and visual reports to inform your business decisions, in a
                                few clicks.</p>
                        </div>
                        <div className="common-title" data-aos="fade-up" data-aos-duration="1500">
                            <h5>Make data actionable</h5>
                            <p>Automate data-driven actions by letting machine intelligence track your data for critical
                                insights.</p>
                        </div>
                        <Link className="btn theme-blue-btn mobile-btn" role="button" to="/product" onClick={()=>{}}>Learn more</Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UseCases
