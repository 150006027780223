import React from 'react'
import {Link, useNavigate} from "react-router-dom"
import {ErrorMessage, Field, Form, Formik} from "formik";
import {SubscribeEmailValidation} from "../../constants/validation";


function Header() {

    function myFunction() {
        let x = document.getElementById("subForm");
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }

    function closeNavbar() {
        let element = document.getElementById("navbarNav");
        element.classList.remove("show");
    }

    const initialValues = {
        email: ''
    }

    const handleSubmit = (values) => {
        window.location = `mailto:contact@smart-apps.ai?subject=Request SmartApps e-book &body=${JSON.stringify(values)}`;
    }

    const navigate = useNavigate()

    return (
        <>
            <section className="top-request-section">
                <div className="container">
                    <div className="row justify-content-center">

                        <span className="d-flex align-items-center col-md-auto col-xs-12 mb-1 mt-1 text-md-center" onClick={myFunction} style={{cursor: "pointer"}}>Request SmartApps e-book on Generative AI for Snowflake</span>


                        <div className="col-md-auto col-xs-12">
                            <div id="subForm" className="stay-date-section" style={{padding: 0, display: 'none'}}>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={SubscribeEmailValidation}
                                    onSubmit={handleSubmit}>

                                    <Form>
                                        <div className="subscribe-input" style={{marginTop: 0}}>
                                            <Field type='email' name='email' placeholder="Enter your email"/>

                                            <button type='submit'>Request</button>
                                        </div>
                                        <span className='text-danger'> <ErrorMessage name='email'/></span>
                                    </Form>
                                </Formik>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <header className="header">
                <nav className="navbar navbar-expand-lg p-0">
                    <div className="container">
                        <Link className="company-logo" to="/">
                            <svg className="company-logo" xmlns="http://www.w3.org/2000/svg"
                                 xmlnsXlink="http://www.w3.org/1999/xlink"
                                 width="744.971" height="228.79" viewBox="0 0 744.971 228.79">
                                <defs>
                                    <linearGradient id="linear-gradient" x1="0.06" y1="0.359" x2="0.782" y2="0.59"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0" stop-color="#263bb8"/>
                                        <stop offset="1" stop-color="#126ed6"/>
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-2" x1="0.101" y1="0.169" x2="0.713" y2="0.677"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0" stop-color="#0989e5"/>
                                        <stop offset="1" stop-color="#15acf6"/>
                                    </linearGradient>
                                    <linearGradient id="linear-gradient-3" x1="0.237" y1="0.672" x2="0.559" y2="0.462"
                                                    gradientUnits="objectBoundingBox">
                                        <stop offset="0" stop-color="#b6e6fe"/>
                                        <stop offset="1" stop-color="#96dafe"/>
                                    </linearGradient>
                                </defs>
                                <g id="HQ" transform="translate(-29.83 -34.439)">
                                    <rect id="Rectangle_17397" data-name="Rectangle 17397" width="744.97"
                                          height="228.79" transform="translate(29.831 34.44)" fill="none"/>
                                    <path id="Path_10339" data-name="Path 10339"
                                          d="M253.91,165.81l4.57-10.15a34.28,34.28,0,0,0,19.23,6.08c8,0,11.23-2.67,11.23-6.25,0-10.9-33.87-3.41-33.87-25.05,0-9.9,8-18.14,24.55-18.14,7.33,0,14.82,1.75,20.23,5.16l-4.16,10.23a33,33,0,0,0-16.15-4.57c-8,0-11.07,3-11.07,6.66,0,10.73,33.87,3.32,33.87,24.71,0,9.74-8.07,18.07-24.71,18.07C268.39,172.56,259.06,169.81,253.91,165.81Z"
                                          fill="#fff"/>
                                    <path id="Path_10340" data-name="Path 10340"
                                          d="M386.32,145.92v25.64h-13V147.92c0-7.24-3.24-10.57-8.49-10.57-5.82,0-9.9,3.74-9.9,11.73v22.48H342V147.92c0-7.24-3.07-10.57-8.48-10.57-5.75,0-9.83,3.74-9.83,11.73v22.48h-13V126.78h12.4v5.16c3.33-3.83,8.32-5.83,14.07-5.83,6.24,0,11.57,2.42,14.73,7.33,3.58-4.58,9.4-7.33,16.15-7.33C378.66,126.11,386.32,132.27,386.32,145.92Z"
                                          fill="#fff"/>
                                    <path id="Path_10341" data-name="Path 10341"
                                          d="M437.58,146v25.55H425.43V166c-2.42,4.08-7.08,6.24-13.65,6.24-10.49,0-16.73-5.82-16.73-13.56,0-7.91,5.57-13.4,19.22-13.4h10.32c0-5.58-3.33-8.83-10.32-8.83a21.55,21.55,0,0,0-12.9,4.17l-4.66-9.08c4.91-3.49,12.15-5.41,19.31-5.41C429.67,126.11,437.58,132.44,437.58,146Zm-13,11.4v-4.58h-8.9c-6.08,0-8,2.25-8,5.24,0,3.25,2.75,5.41,7.32,5.41,4.35.01,8.1-1.98,9.59-6.06Z"
                                          fill="#fff"/>
                                    <path id="Path_10342" data-name="Path 10342"
                                          d="M477.11,126.11v12c-1.09-.08-1.92-.17-2.92-.17-7.16,0-11.9,3.91-11.9,12.49v21.14h-13V126.78h12.4v5.91C464.87,128.36,470.2,126.11,477.11,126.11Z"
                                          fill="#fff"/>
                                    <path id="Path_10343" data-name="Path 10343"
                                          d="M515.31,169.39c-2.67,1.92-6.58,2.83-10.58,2.83-10.57,0-16.73-5.41-16.73-16.06V137.77h-6.9v-10H488v-10.9h13v10.91h11.15v10H501V156c0,3.83,2.08,5.91,5.58,5.91a8.77,8.77,0,0,0,5.24-1.66Z"
                                          fill="#fff"/>
                                    <path id="Path_10344" data-name="Path 10344"
                                          d="M561.91,159.07h-27l-5.16,12.49H515.88l26-58.26H555.2l26.05,58.26H567.07Zm-4.24-10.23-9.24-22.31-9.24,22.31Z"
                                          fill="#fff"/>
                                    <path id="Path_10345" data-name="Path 10345"
                                          d="M635.15,149.17c0,14.06-9.74,23.05-22.31,23.05-5.58,0-10.15-1.75-13.48-5.41V187.7h-13V126.78h12.4v5.16c3.25-3.91,8.08-5.83,14.07-5.83C625.41,126.11,635.15,135.1,635.15,149.17Zm-13.15,0c0-7.74-4.92-12.4-11.41-12.4s-11.4,4.66-11.4,12.4,4.91,12.4,11.4,12.4S622,156.91,622,149.17Z"
                                          fill="#fff"/>
                                    <path id="Path_10346" data-name="Path 10346"
                                          d="M692.57,149.17c0,14.06-9.74,23.05-22.31,23.05-5.58,0-10.15-1.75-13.48-5.41V187.7h-13V126.78H656.2v5.16c3.24-3.91,8.07-5.83,14.06-5.83C682.83,126.11,692.57,135.1,692.57,149.17Zm-13.15,0c0-7.74-4.91-12.4-11.41-12.4s-11.4,4.66-11.4,12.4,4.91,12.4,11.4,12.4S679.42,156.91,679.42,149.17Z"
                                          fill="#fff"/>
                                    <path id="Path_10347" data-name="Path 10347"
                                          d="M696.89,167.39l4.33-9.32a30.62,30.62,0,0,0,15.56,4.33c6,0,8.24-1.58,8.24-4.08,0-7.32-27,.17-27-17.72,0-8.49,7.66-14.49,20.72-14.49,6.16,0,13,1.42,17.23,3.92l-4.33,9.23a25.78,25.78,0,0,0-12.9-3.33c-5.82,0-8.32,1.84-8.32,4.17,0,7.65,27,.25,27,17.89,0,8.32-7.74,14.23-21.14,14.23C708.71,172.22,701.05,170.14,696.89,167.39Z"
                                          fill="#fff"/>
                                    <path id="Path_10348" data-name="Path 10348"
                                          d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                    <path id="Path_10349" data-name="Path 10349"
                                          d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                    <path id="Path_10350" data-name="Path 10350"
                                          d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                          fill="url(#linear-gradient-3)"/>
                                    <g id="Group_8274" data-name="Group 8274" transform="translate(1)">
                                        <path id="Path_10371" data-name="Path 10371"
                                              d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                        <path id="Path_10369" data-name="Path 10369"
                                              d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                        <path id="Path_10370" data-name="Path 10370"
                                              d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                              fill="url(#linear-gradient-3)"/>
                                    </g>
                                    <g id="Group_8278" data-name="Group 8278" transform="translate(27 -46.59)">
                                        <path id="Path_10371-2" data-name="Path 10371"
                                              d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                        <path id="Path_10369-2" data-name="Path 10369"
                                              d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                        <path id="Path_10370-2" data-name="Path 10370"
                                              d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                              fill="url(#linear-gradient-3)"/>
                                    </g>
                                    <g id="Group_8279" data-name="Group 8279" transform="translate(27 47)">
                                        <path id="Path_10371-3" data-name="Path 10371"
                                              d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                        <path id="Path_10369-3" data-name="Path 10369"
                                              d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                        <path id="Path_10370-3" data-name="Path 10370"
                                              d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                              fill="url(#linear-gradient-3)"/>
                                    </g>
                                    <g id="Group_8280" data-name="Group 8280" transform="translate(54)">
                                        <path id="Path_10371-4" data-name="Path 10371"
                                              d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                        <path id="Path_10369-4" data-name="Path 10369"
                                              d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                        <path id="Path_10370-4" data-name="Path 10370"
                                              d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                              fill="url(#linear-gradient-3)"/>
                                    </g>
                                    <g id="Group_8281" data-name="Group 8281" transform="translate(81 -46.59)">
                                        <path id="Path_10371-5" data-name="Path 10371"
                                              d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                        <path id="Path_10369-5" data-name="Path 10369"
                                              d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                        <path id="Path_10370-5" data-name="Path 10370"
                                              d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                              fill="url(#linear-gradient-3)"/>
                                    </g>
                                    <g id="Group_8282" data-name="Group 8282" transform="translate(81 47)">
                                        <path id="Path_10371-6" data-name="Path 10371"
                                              d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                        <path id="Path_10369-6" data-name="Path 10369"
                                              d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                        <path id="Path_10370-6" data-name="Path 10370"
                                              d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                              fill="url(#linear-gradient-3)"/>
                                    </g>
                                    <g id="Group_8283" data-name="Group 8283" transform="translate(107)">
                                        <path id="Path_10371-7" data-name="Path 10371"
                                              d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                        <path id="Path_10369-7" data-name="Path 10369"
                                              d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                        <path id="Path_10370-7" data-name="Path 10370"
                                              d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                              fill="url(#linear-gradient-3)"/>
                                    </g>
                                </g>
                            </svg>

                        </Link>
                        <button className="toggle" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <div className="container">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link onClick={closeNavbar} to="/product" className="nav-link active">Product</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link onClick={closeNavbar} className="nav-link" to="./blog">Blog</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link onClick={closeNavbar} className="nav-link" to="./research">Research</Link>
                                    </li>

                                </ul>
                                <div className="mobile-view-btn">
                                    <Link onClick={closeNavbar} to="./free-trial-form" className="btn login-btn">Log in</Link>
                                    <Link onClick={closeNavbar} to="./free-trial-form" className="btn stroked-btn">Try for free</Link>
                                </div>
                            </div>
                        </div>
                        <div className="desktop-view-btn">
                            <Link onClick={closeNavbar} to="./free-trial-form" className="btn login-btn">Log in</Link>
                            <Link onClick={closeNavbar} to="./free-trial-form" className="btn stroked-btn">Try for free</Link>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header
