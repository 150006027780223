import React, {useEffect, useState} from 'react'
import axios from 'axios';

function BlogCard() {
    const [blogData, setBlogData] = useState({})

    let getUsersData = () => {
        axios.get('https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fblog.smart-apps.ai%2Ffeed')
            .then((response) => setBlogData(response.data))
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        getUsersData()
    }, [])


    const data = blogData?.items?.sort((a, b) => {
        return new Date(b.pubDate) - new Date(a.pubDate);
    })?.map((item) => {
        const div = document.createElement('div')
        div.innerHTML = item.description;

        return {
            author: item?.author,
            pubDate: item?.pubDate,
            title: item?.title,
            link: item?.link,
            imgUrl: div?.querySelector('figure>img')?.src
        }
    })
    if (data && data.length) {
        return (

            <section className="rethinking-data-section overflow-hidden">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="">
                                <img src={data[0]?.imgUrl} alt='autor_image'/>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <div className="right-rethinking-data">
                                <div className="rethinking-data">
                                    <div className="rethinking-user">
                                        <span className="user-image"></span>
                                        {/* <div>
                                        <h6>{blogData?.feed?.author}</h6>
                                        <b>Jul 13</b> /
                                        <span>6 min read</span>
                                    </div>  */}
                                    </div>
                                </div>
                                <div className="rethinking-title">
                                    <h4>{data[0]?.title}</h4>
                                    {/*<p>{data[0]?.description}</p>*/}
                                </div>
                                <a href={data[0]?.link} className="read-more-btn" target='_blank'>Read more <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    viewBox="0 0 16 16" fill="none">
                                    <path fill-rule="evenodd" clipRule="evenodd"
                                          d="M10.7941 7.85072C10.9747 8.03134 10.9747 8.32413 10.7941 8.50474L6.07883 13.22C5.89821 13.4006 5.60542 13.4006 5.4248 13.22L5.20675 13.002C5.02613 12.8214 5.02613 12.5285 5.20675 12.3479L9.37696 8.17773L5.20675 4.00752C5.02613 3.8269 5.02613 3.53411 5.20675 3.35349L5.4248 3.13544C5.60542 2.95482 5.89821 2.95482 6.07883 3.13544L10.7941 7.85072Z"
                                          fill="#141E35"/>
                                </svg></a>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        {
                            data.splice(1).map((item, index) => {
                                return (
                                    <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                                        <div className="small-rethinking-card">
                                            <img src={item?.imgUrl}/>
                                            <div className="small-rethinking-data">
                                                <div className="rethinking-data">
                                                    <div className="rethinking-user">
                                                        <span className="user-image"></span>
                                                        <div>
                                                            <h6>{item?.author}</h6>
                                                            <span>{new Date(item?.pubDate).toLocaleDateString('en-GB')}</span>
                                                            {/* <span>6 min read</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="rethinking-title">
                                                    <h4>{item?.title}</h4>
                                                    {/* <p>They matter and we are lucky to have them.</p> */}
                                                </div>
                                                <a href={item?.link} className="read-more-btn" target='_blank'>Read
                                                    more <svg
                                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none">
                                                        <path fill-rule="evenodd" clipRule="evenodd"
                                                              d="M10.7941 7.85072C10.9747 8.03134 10.9747 8.32413 10.7941 8.50474L6.07883 13.22C5.89821 13.4006 5.60542 13.4006 5.4248 13.22L5.20675 13.002C5.02613 12.8214 5.02613 12.5285 5.20675 12.3479L9.37696 8.17773L5.20675 4.00752C5.02613 3.8269 5.02613 3.53411 5.20675 3.35349L5.4248 3.13544C5.60542 2.95482 5.89821 2.95482 6.07883 3.13544L10.7941 7.85072Z"
                                                              fill="#141E35"/>
                                                    </svg></a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }


                    </div>
                </div>
            </section>
        )

    }
    return (
        <section className="rethinking-data-section overflow-hidden">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                </div>
            </div>
            <div className="row mt-5"></div>
        </section>

    )

}

export default BlogCard
