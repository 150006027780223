import React from 'react'

function ResearchBanner() {
    return (
        <section className="smartlets-section overflow-hidden">
            <div className="container">
                <div className="common-title text-center">
                    <h5>Research</h5>
                </div>
            </div>
        </section>
    )
}

export default ResearchBanner
