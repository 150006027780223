import React from 'react'
function SeeAction() {
    return (
        <section className="see-action-section overflow-hidden">
            <div className="container">
                <div className="see-action-contant">
                    <h4>See it in action!</h4>
                    <a href="./free-trial-form" className="btn theme-blue-btn" data-aos="fade-up">Get a Free Trial</a>
                </div>
            </div>
        </section>
    )
}

export default SeeAction
