import {useEffect} from 'react';
import './App.css';
import Home from './view/home/Home';
import AOS from "aos";
import "aos/dist/aos.css"
import {Route, Routes} from 'react-router-dom';
import FreeTrialForm from './view/Free-trial/FreeTrialForm';
import Header from './view/common/Header'
import Footer from './view/common/Footer';
import Product from './view/product/Product';
import About from "./view/About/About";
import Blog from "./view/Blog/Blog";
import ScrollToTop from "./components/ScrollToTop";

import Research from "./view/research/Research";
import TermsConditions from "./view/terms-conditions/Terms-conditions";

function App() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div className="App">
            <ScrollToTop/>
            <Header/>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/free-trial-form' element={<FreeTrialForm/>}/>
                <Route path='/product' element={<Product/>}/>
                <Route path='/about' element={<About/>}/>
                <Route path='/blog' element={<Blog/>}/>
                <Route path='/research' element={<Research/>}/>
                <Route path='/terms-conditions' element={<TermsConditions/>}/>
            </Routes>
            <Footer/>
        </div>
    );
}

export default App;
