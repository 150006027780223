import React from 'react'
import chart1 from '../../assets/image/chart-1.png'
import video from '../../assets/videos/automated-dashboard.mp4'

function AutomatedDashboard() {
    return (
        <div className="reliable-data-section overflow-hidden">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-6 col-md-12 col-sm-12 order-lg-1 order-md-2 order-sm-2 order-2">
                        <div>
                            <video width="100%" autoPlay playsInline muted loop>
                                <source src={video} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12 order-lg-2 order-md-1 order-sm-1 order-1">
                        <div className="common-title" data-aos="fade-left">
                            <h5>Automated Dashboards</h5>
                            <p>Make dashboard creation a matter of few clicks with generated data modeling, data
                                pipelines, and
                                data refresh. Easy, lightning-fast dashboards are for everyone.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
)}

export default AutomatedDashboard
