import React from 'react'
import BlogBanner from "./BlogBanner";
import SubscribeNewsletter from "./SubscribeNewsletter";
import BlogCard from "./BlogCard";


function Blog() {
    return (
        <div>
            <BlogBanner/>
            <BlogCard/>
            <SubscribeNewsletter/>
        </div>
    )
}

export default Blog
