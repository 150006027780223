import React, {useEffect} from 'react'
import video from '../../assets/videos/teaser.mp4'
import {useLottie} from "lottie-react";
import brainAnimation from '../../assets/lottie-animations/data.json';

function Banner() {
    let start = false
    const options = {
        animationData: brainAnimation,
        loop: start,
    };
    const {View} = useLottie(options);
    useEffect(() => {
        function handleShowModelPopup() {
            document.getElementById('productVideo')?.play();
        }

        function handleHideModelPopup() {
            document.getElementById('productVideo')?.pause();
        }

        document.getElementById('staticBackdrop')?.addEventListener('shown.bs.modal', handleShowModelPopup)
        document.getElementById('staticBackdrop')?.addEventListener('hidden.bs.modal', handleHideModelPopup)
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.getElementById('staticBackdrop')?.removeEventListener('shown.bs.modal', handleShowModelPopup)
            document.getElementById('staticBackdrop')?.removeEventListener('hidden.bs.modal', handleHideModelPopup)
        };
    });

    return (
        <div>
            <section className="generative-ai">
                <div className="container">
                    <div className="">
                        <h1 data-aos="fade-up">
                            Generative
                            <label className="ai">
                                <span>Ai</span>
                                <span>Ai</span>
                            </label> for <br/> Enterprise Data
                        </h1>
                        <div className="enterprise-data-list" data-aos="fade-up">
                            <span>Hallucination-free</span>
                            <span>Cloud-scale </span>
                            <span>Enterprise-secure</span>
                        </div>
                        <a href="./free-trial-form" className="btn theme-blue-btn" data-aos="fade-up">Try for free</a>
                    </div>
                    <div className="ai-image-container" data-aos="fade-up">
                        {/*<img src={aiImage} alt='ai' />*/}
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-xs-12 col-sm-12" data-bs-toggle="modal"
                                 data-bs-target="#staticBackdrop">

                                <>{View}</>
                            </div>
                        </div>


                    </div>
                </div>
            </section>


            <div className="modal fade bd-example-modal-lg" id="staticBackdrop" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content ">

                        <div className="modal-body">
                            <button type="button" className="btn-close close-videos-model" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            <video width="100%" controls loop id="productVideo">
                                <source src={video} type="video/mp4"></source>
                                <source src={video} type="video/ogg"></source>
                            </video>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
