import React from 'react'
import Banner from './Banner'
import Counter from './Counter'
import Hallucination from './Hallucination'
import Enterprise from './Enterprise'
import IntroducingData from './IntroducingData'
import ReliableData from './ReliableData'
import CostEffectiveData from './CostEffectiveData'
import OperatorsCarouselData from './OperatorsCarouselData'
import GetStarted from './GetStarted'
import UseCases from './UseCases'

function Home() {
  return (
    <div>
      <Banner />
      <Counter />
      <Hallucination />
      <Enterprise />
      <IntroducingData />
      <ReliableData />
      <CostEffectiveData />
      <OperatorsCarouselData />
      <GetStarted />
      <UseCases />
    </div>
  )
}

export default Home
