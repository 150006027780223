import React from 'react'
import ProductBanner from './ProductBanner'
import Supercharge from "./supercharge";
import Automated from "./Automated";
import AutomatedDashboard from "./AutomatedDashboard";
import Monitoring from "./Monitoring";
import AutomatedReports from "./AutomatedReports";
import SeeAction from "./SeeAction";

function Product() {
    return (
        <div>
            <ProductBanner/>
            <Supercharge/>
            <Automated/>
            <AutomatedDashboard/>
            <Monitoring/>
            <AutomatedReports/>
            <SeeAction/>
        </div>
    )
}

export default Product
