import React from 'react'

function TermsConditionsBanner() {
    return (
        <section className="smartlets-section overflow-hidden">
            <div className="container">
                <div className="common-title text-center">
                    <h5>Terms of Use</h5>
                </div>
            </div>
        </section>
    )
}

export default TermsConditionsBanner
