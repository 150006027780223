import React from 'react'
import AboutBanner from "./AboutBanner";
import Leadership from "./Leadership";


function About() {
    return (
        <div>
            <AboutBanner/>
            <Leadership/>
        </div>
    )
}

export default About
