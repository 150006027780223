import React from 'react'

function BlogBanner() {
    return (
        <section className="smartlets-section overflow-hidden">
            <div className="container">
                <div className="common-title text-center">
                    <h5>Smartlets</h5>
                    <p>Turning data into intelligence.</p>
                </div>
            </div>
        </section>
    )
}

export default BlogBanner
