import {ErrorMessage, Field, Form, Formik} from 'formik'
import React from 'react'
import {SubscribeEmailValidation} from '../../constants/validation'

function SubscribeNewsletter() {

    const initialValues = {
        email: ''
    }

    const handleSubmit = (values) => {
        window.location = `mailto:contact@smart-apps.ai?subject=I want to Subscribe  your newsletter &body=${JSON.stringify(values)}`;
    }

    return (
        <section className="stay-date-section overflow-hidden">
            <div className="container">
                <Formik
                    initialValues={initialValues}
                    validationSchema={SubscribeEmailValidation}
                    onSubmit={handleSubmit}>

                    <Form>
                        <div className="row justify-content-between">
                            <div className="col-lg-5 col-md-6 col-sm-12">

                                <div className="common-title">
                                    <h5>Stay up to date</h5>
                                    <p>Subscribe to our newsletter to get inbox notifications.</p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <div className="common-title">
                                    <p>Sign up to our newsletter ↓</p>
                                    <div className="subscribe-input">
                                        <Field type='email' name='email' placeholder="Enter your email"/>

                                        <button type='submit'>Subscribe</button>
                                    </div>
                                    <span className='text-danger'> <ErrorMessage name='email'/></span>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </section>
    )
}

export default SubscribeNewsletter
