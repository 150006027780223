import React, {useState} from 'react'
import data from '../../assets/json/SubscribeNewsletter.json'
import SearchNotFound from "../../assets/image/search-not-found.png";


function ResearchListData() {
    const [search, setSearch] = useState('')
    const [researchData, setResearchData] = useState(data)
    const [selectedAuthorName, setSelectedAuthorName] = useState("")
    const authorsData = []

    const getauthorsName = () => {
        researchData.Data.filter((ele) => ele.Description.split(',')
            .map((element) => {
            authorsData.push(element)
        }))
    }

    getauthorsName()
    let authorsName = authorsData.filter((item, index) => authorsData.indexOf(item) === index).sort((a, b) => a < b ? -1 : 1);
    
    const sortDataByAuthorName = (e) => {
        setSelectedAuthorName(e)
    }


    return (
        <section className='research-list-data'>
            <div className='container'>
                <div>
                    <div className="list-search row">
                        <div className="col-md-auto flex-fill col-xs-12 mt-3">
                            <input type='serch' className="w-100 me-0" placeholder="Search" onChange={(e) => setSearch(e.target.value)}/>
                        </div>

                        <div className="col-md-auto col-xs-12 mt-3">
                            <select className='authorsName w-100' onChange={(e) => sortDataByAuthorName(e.target.value)}>
                                <option key={-1} value=''>--All Author--</option>
                                {
                                    authorsName.map((ele, ind) => {
                                        return (
                                            <option key={ind} value={ele}>{ele}</option>
                                        )
                                    })
                                }
                                {/* <option value="volvo">Sort : Author</option>
                                <option value="saab">Author 2</option>
                                <option value="mercedes">Author 3</option>
                                <option value="audi">Author 4</option> */}
                            </select>
                        </div>
                    </div>

                    {

                        researchData.Data?.filter((item) => {

                            return (selectedAuthorName === '' ? item : item.Description.toLowerCase().includes(selectedAuthorName.toLowerCase())) &&
                                (search.toLowerCase() === '' ? item : item.Title.toLowerCase().includes(search.toLocaleLowerCase()))
                        })?.map((res, index) => {
                            return (
                                <div className='list-data' key={index}>
                                    <a className="panel-title" href={res?.link}>{res?.Title}</a>
                                    <label>{res?.Description}</label>
                                    <span className='list-year'>
                            {res?.PublicationYear}</span>
                                </div>
                            )
                        })
                    }
                </div>
                {researchData.Data?.filter((item) => {

                    return (selectedAuthorName === '' ? item : item.Description.toLowerCase().includes(selectedAuthorName.toLowerCase())) &&
                        (search.toLowerCase() === '' ? item : item.Title.toLowerCase().includes(search.toLocaleLowerCase()))
                })?.length === 0 && <div className='search-not-found'>
                    <img src={SearchNotFound} alt='chart'/>
                    <span>Search not Found</span>
                </div>}
            </div>
        </section>
    )
}

export default ResearchListData
