import React from 'react'

function Counter() {
    return (
        <section className="counter-section overflow-hidden">
            <div className=" container">
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <div className="counter-contant" data-aos="zoom-in" data-aos-duration="1000">
                            <h3>100x</h3>
                            <span>PRODUCTIVE</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="counter-contant" data-aos="zoom-in" data-aos-duration="2000">
                            <h3>20x</h3>
                            <span>FASTER</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="counter-contant" data-aos="zoom-in" data-aos-duration="3000">
                            <h3>5x</h3>
                            <span>CHEAPER</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Counter
