import React from 'react'

function ProductBanner() {
    return (
        <section className="making-data-section overflow-hidden">
            <div className="container">
                <div className="">
                    <h1 data-aos="fade-up">Power up generative AI <br/> within 30 minutes</h1>
                    <a href="./free-trial-form" className="btn theme-blue-btn" data-aos="fade-up">Try for free</a>
                </div>
            </div>
        </section>
    )
}

export default ProductBanner
