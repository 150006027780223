import React from 'react'
import ResearchBanner from "./ResearchBanner";
import ResearchListData from "./Research-list-data";

function Research() {
    return (
        <div>
            <ResearchBanner/>
            <ResearchListData/>
        </div>
    )
}

export default Research
