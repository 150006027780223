import React from 'react'
import TermsConditionsBanner from "./Terms-conditions-banner";
import TermsConditionsData from "./Terms-conditions-data";


function TermsConditions() {
    return (
        <div>
            <TermsConditionsBanner/>
            <TermsConditionsData/>
        </div>
    )
}

export default TermsConditions
