import React from 'react'

import {useLottie} from "lottie-react";
import aboutAnimation from '../../assets/lottie-animations/aboutData.json';
function AboutBanner() {
    const options = {
        animationData: aboutAnimation,
        loop: true,

    };
    const {View} = useLottie(options);
    return (
        <section className="making-data-section overflow-hidden">
            <div className="container">
                <div>
                    <h1 data-aos="fade-up">Making data talk <br/> to everyone</h1>
                    <p>Our vision is to help over a billion working professionals work smarter with data.</p>
                </div>
            </div>
            <div className="about-icon">
                <>{View}</>
            </div>
        </section>
    )
}

export default AboutBanner
