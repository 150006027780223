import React from 'react'
import video from '../../assets/videos/teaser.mp4'

function Supercharge() {
    return (
        <section className="supercharge-section overflow-hidden">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="supercharge-data">
                            <div className="title-container">
                                <h5>Supercharge your data productivity and take your business to the next level.</h5>
                                <p>Embrace the next generation analytics stack to stay ahead of competition. Try out the
                                    fully
                                    automated generative AI solution.</p>
                            </div>
                            <span>
                                <video width="100%" controls>
                                    <source src={video} type="video/mp4" />
                                </video>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Supercharge
