import React from 'react'
import {Link} from "react-router-dom";

function Footer() {
    const d = new Date();
let year = d.getFullYear();
  return (
    <div className="footer-section">
    <div className="container">
        <div className="row justify-content-between">
            <div className="col-md-5 col-sm-12">
                <div>
                    <Link className="company-logo mb-3" to="/">
                        <svg className="company-logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                             width="744.971" height="228.79" viewBox="0 0 744.971 228.79">
                            <defs>
                                <linearGradient id="linear-gradient" x1="0.06" y1="0.359" x2="0.782" y2="0.59"
                                                gradientUnits="objectBoundingBox">
                                    <stop offset="0" stop-color="#263bb8"/>
                                    <stop offset="1" stop-color="#126ed6"/>
                                </linearGradient>
                                <linearGradient id="linear-gradient-2" x1="0.101" y1="0.169" x2="0.713" y2="0.677"
                                                gradientUnits="objectBoundingBox">
                                    <stop offset="0" stop-color="#0989e5"/>
                                    <stop offset="1" stop-color="#15acf6"/>
                                </linearGradient>
                                <linearGradient id="linear-gradient-3" x1="0.237" y1="0.672" x2="0.559" y2="0.462"
                                                gradientUnits="objectBoundingBox">
                                    <stop offset="0" stop-color="#b6e6fe"/>
                                    <stop offset="1" stop-color="#96dafe"/>
                                </linearGradient>
                            </defs>
                            <g id="HQ" transform="translate(-29.83 -34.439)">
                                <rect id="Rectangle_17397" data-name="Rectangle 17397" width="744.97"
                                      height="228.79" transform="translate(29.831 34.44)" fill="none"/>
                                <path id="Path_10339" data-name="Path 10339"
                                      d="M253.91,165.81l4.57-10.15a34.28,34.28,0,0,0,19.23,6.08c8,0,11.23-2.67,11.23-6.25,0-10.9-33.87-3.41-33.87-25.05,0-9.9,8-18.14,24.55-18.14,7.33,0,14.82,1.75,20.23,5.16l-4.16,10.23a33,33,0,0,0-16.15-4.57c-8,0-11.07,3-11.07,6.66,0,10.73,33.87,3.32,33.87,24.71,0,9.74-8.07,18.07-24.71,18.07C268.39,172.56,259.06,169.81,253.91,165.81Z"
                                      fill="#fff"/>
                                <path id="Path_10340" data-name="Path 10340"
                                      d="M386.32,145.92v25.64h-13V147.92c0-7.24-3.24-10.57-8.49-10.57-5.82,0-9.9,3.74-9.9,11.73v22.48H342V147.92c0-7.24-3.07-10.57-8.48-10.57-5.75,0-9.83,3.74-9.83,11.73v22.48h-13V126.78h12.4v5.16c3.33-3.83,8.32-5.83,14.07-5.83,6.24,0,11.57,2.42,14.73,7.33,3.58-4.58,9.4-7.33,16.15-7.33C378.66,126.11,386.32,132.27,386.32,145.92Z"
                                      fill="#fff"/>
                                <path id="Path_10341" data-name="Path 10341"
                                      d="M437.58,146v25.55H425.43V166c-2.42,4.08-7.08,6.24-13.65,6.24-10.49,0-16.73-5.82-16.73-13.56,0-7.91,5.57-13.4,19.22-13.4h10.32c0-5.58-3.33-8.83-10.32-8.83a21.55,21.55,0,0,0-12.9,4.17l-4.66-9.08c4.91-3.49,12.15-5.41,19.31-5.41C429.67,126.11,437.58,132.44,437.58,146Zm-13,11.4v-4.58h-8.9c-6.08,0-8,2.25-8,5.24,0,3.25,2.75,5.41,7.32,5.41,4.35.01,8.1-1.98,9.59-6.06Z"
                                      fill="#fff"/>
                                <path id="Path_10342" data-name="Path 10342"
                                      d="M477.11,126.11v12c-1.09-.08-1.92-.17-2.92-.17-7.16,0-11.9,3.91-11.9,12.49v21.14h-13V126.78h12.4v5.91C464.87,128.36,470.2,126.11,477.11,126.11Z"
                                      fill="#fff"/>
                                <path id="Path_10343" data-name="Path 10343"
                                      d="M515.31,169.39c-2.67,1.92-6.58,2.83-10.58,2.83-10.57,0-16.73-5.41-16.73-16.06V137.77h-6.9v-10H488v-10.9h13v10.91h11.15v10H501V156c0,3.83,2.08,5.91,5.58,5.91a8.77,8.77,0,0,0,5.24-1.66Z"
                                      fill="#fff"/>
                                <path id="Path_10344" data-name="Path 10344"
                                      d="M561.91,159.07h-27l-5.16,12.49H515.88l26-58.26H555.2l26.05,58.26H567.07Zm-4.24-10.23-9.24-22.31-9.24,22.31Z"
                                      fill="#fff"/>
                                <path id="Path_10345" data-name="Path 10345"
                                      d="M635.15,149.17c0,14.06-9.74,23.05-22.31,23.05-5.58,0-10.15-1.75-13.48-5.41V187.7h-13V126.78h12.4v5.16c3.25-3.91,8.08-5.83,14.07-5.83C625.41,126.11,635.15,135.1,635.15,149.17Zm-13.15,0c0-7.74-4.92-12.4-11.41-12.4s-11.4,4.66-11.4,12.4,4.91,12.4,11.4,12.4S622,156.91,622,149.17Z"
                                      fill="#fff"/>
                                <path id="Path_10346" data-name="Path 10346"
                                      d="M692.57,149.17c0,14.06-9.74,23.05-22.31,23.05-5.58,0-10.15-1.75-13.48-5.41V187.7h-13V126.78H656.2v5.16c3.24-3.91,8.07-5.83,14.06-5.83C682.83,126.11,692.57,135.1,692.57,149.17Zm-13.15,0c0-7.74-4.91-12.4-11.41-12.4s-11.4,4.66-11.4,12.4,4.91,12.4,11.4,12.4S679.42,156.91,679.42,149.17Z"
                                      fill="#fff"/>
                                <path id="Path_10347" data-name="Path 10347"
                                      d="M696.89,167.39l4.33-9.32a30.62,30.62,0,0,0,15.56,4.33c6,0,8.24-1.58,8.24-4.08,0-7.32-27,.17-27-17.72,0-8.49,7.66-14.49,20.72-14.49,6.16,0,13,1.42,17.23,3.92l-4.33,9.23a25.78,25.78,0,0,0-12.9-3.33c-5.82,0-8.32,1.84-8.32,4.17,0,7.65,27,.25,27,17.89,0,8.32-7.74,14.23-21.14,14.23C708.71,172.22,701.05,170.14,696.89,167.39Z"
                                      fill="#fff"/>
                                <path id="Path_10348" data-name="Path 10348"
                                      d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                <path id="Path_10349" data-name="Path 10349"
                                      d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                <path id="Path_10350" data-name="Path 10350"
                                      d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                      fill="url(#linear-gradient-3)"/>
                                <g id="Group_8274" data-name="Group 8274" transform="translate(1)">
                                    <path id="Path_10371" data-name="Path 10371"
                                          d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                    <path id="Path_10369" data-name="Path 10369"
                                          d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                    <path id="Path_10370" data-name="Path 10370"
                                          d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                          fill="url(#linear-gradient-3)"/>
                                </g>
                                <g id="Group_8278" data-name="Group 8278" transform="translate(27 -46.59)">
                                    <path id="Path_10371-2" data-name="Path 10371"
                                          d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                    <path id="Path_10369-2" data-name="Path 10369"
                                          d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                    <path id="Path_10370-2" data-name="Path 10370"
                                          d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                          fill="url(#linear-gradient-3)"/>
                                </g>
                                <g id="Group_8279" data-name="Group 8279" transform="translate(27 47)">
                                    <path id="Path_10371-3" data-name="Path 10371"
                                          d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                    <path id="Path_10369-3" data-name="Path 10369"
                                          d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                    <path id="Path_10370-3" data-name="Path 10370"
                                          d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                          fill="url(#linear-gradient-3)"/>
                                </g>
                                <g id="Group_8280" data-name="Group 8280" transform="translate(54)">
                                    <path id="Path_10371-4" data-name="Path 10371"
                                          d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                    <path id="Path_10369-4" data-name="Path 10369"
                                          d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                    <path id="Path_10370-4" data-name="Path 10370"
                                          d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                          fill="url(#linear-gradient-3)"/>
                                </g>
                                <g id="Group_8281" data-name="Group 8281" transform="translate(81 -46.59)">
                                    <path id="Path_10371-5" data-name="Path 10371"
                                          d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                    <path id="Path_10369-5" data-name="Path 10369"
                                          d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                    <path id="Path_10370-5" data-name="Path 10370"
                                          d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                          fill="url(#linear-gradient-3)"/>
                                </g>
                                <g id="Group_8282" data-name="Group 8282" transform="translate(81 47)">
                                    <path id="Path_10371-6" data-name="Path 10371"
                                          d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                    <path id="Path_10369-6" data-name="Path 10369"
                                          d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                    <path id="Path_10370-6" data-name="Path 10370"
                                          d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                          fill="url(#linear-gradient-3)"/>
                                </g>
                                <g id="Group_8283" data-name="Group 8283" transform="translate(107)">
                                    <path id="Path_10371-7" data-name="Path 10371"
                                          d="M116.36,134.47v31.06L89.47,181.06V150Z" fill="url(#linear-gradient)"/>
                                    <path id="Path_10369-7" data-name="Path 10369"
                                          d="M89.47,150v31.06L62.58,165.53V134.47Z" fill="url(#linear-gradient-2)"/>
                                    <path id="Path_10370-7" data-name="Path 10370"
                                          d="M116.36,134.47,89.47,150,62.58,134.47l26.89-15.53Z"
                                          fill="url(#linear-gradient-3)"/>
                                </g>
                            </g>
                        </svg>
                    </Link>
                    <p className="mt-4">SmartApps is a generative AI company that solves critical barriers for businesses adopting generative AI, including hallucination, privacy, cloud-scale, and overall productivity. We make generative AI plug-and-play for all your
                        data, helping you to take your business to the next level.</p>
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="d-flex justify-content-between footer-right-contant">
                    <div>
                        <ul>
                            <li>
                                <Link to="./product">Product</Link>
                            </li>
                            <li>
                                <Link to="./blog">Blog</Link>
                            </li>
                            <li>
                                <Link to="./research">Research</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <Link to="./about">About</Link>
                            </li>
                            <li>
                                <Link to="./free-trial-form">Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <label>Follow us</label>
                        <div className="mt-2">
                            <Link to="https://www.linkedin.com/company/90679749">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"
                                     fill="none">
                                    <path d="M12.833 11.0634V11.0322C12.833 11.0322 12.8018 11.0322 12.833 11.0634Z"
                                          fill="white"/>
                                    <path
                                        d="M22.0813 0.243164H1.76147C0.78637 0.243164 0 0.998079 0 1.97318V22.5132C0 23.4568 0.78637 24.2432 1.76147 24.2432H22.0813C23.0564 24.2432 23.8427 23.4883 23.8427 22.5132V1.94172C23.8427 0.998079 23.0564 0.243164 22.0813 0.243164ZM7.2346 20.3113H3.64876V9.49087H7.2346V20.3113ZM5.44168 8.0125C4.21494 8.0125 3.42857 7.19467 3.42857 6.15666C3.42857 5.0872 4.2464 4.30083 5.47313 4.30083C6.69987 4.30083 7.45478 5.11866 7.48624 6.15666C7.48624 7.16322 6.69987 8.0125 5.44168 8.0125ZM20.2254 20.3113H16.6081V14.5236C16.6081 13.0767 16.0734 12.0702 14.7837 12.0702C13.7772 12.0702 13.211 12.7307 12.9279 13.3913C12.8336 13.6114 12.8021 13.9575 12.8021 14.272V20.3113H9.21625C9.21625 20.3113 9.27916 10.4974 9.21625 9.49087H12.8021V11.0322C13.2739 10.3087 14.1232 9.23923 16.0419 9.23923C18.401 9.23923 20.194 10.7805 20.194 14.1147V20.3113H20.2254Z"
                                        fill="white"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row mt-5">
            <div className="col-md-6 col-sm-12">
                <span>{year} © SmartApps. All Rights Reserved.</span>
            </div>
            <div className="col-md-6 col-sm-12">
                <Link to="./terms-conditions" className='terms-link'>Terms & Conditions</Link>
            </div>
        </div>
    </div>
</div>
  )
}

export default Footer
